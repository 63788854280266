import axios from '@/utils/request'

/**
 * 登录接口
 * @data {*} data
 */
export function login(data) {
    return axios({
        url: '/login',
        method: 'post',
        data
    })
}

/**
 * 登出接口
 * @data {*} data
 */
export function logout(data) {
    return axios({
        url: '/logout',
        method: 'post',
        data
    })
}

/**
 * 登出接口
 * @data {*} data
 */
export function loginSystemInfo(data) {
    return axios({
        url: '/login-system/info',
        method: 'post',
        data
    })
}
