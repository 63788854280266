import Cookies from 'js-cookie'
/**
 * 存储Storage
 */
export const setStorage = (name, content) => {
    if (!name) return
    if (typeof content !== 'string') {
        content = JSON.stringify(content)
    }
    Cookies.set(name, content, { expires: 365 })
}

/**
 * 获取Storage
 */
export const getStorage = name => {
    if (!name) return
    let content = Cookies.get(name)
    try {
        content = JSON.parse(content)
        return content
    } catch (e) {
        return content
    }
}

/**
 * 删除Storage
 */
export const removeStorage = name => {
    if (!name) return
    Cookies.remove(name)
}

/**
 * 删除全部Storage
 */
export const removeAllStorage = name => {
    if (!(name instanceof Array)) return
    name.forEach(el => {
        Cookies.remove(el)
    })
}
