/**
 * 处理数据
 * 参数 list: 数据源;
 * 参数 key：对象中键名对应的字段名;
 * 参数 value：对象中键值对应的字段名;
 * 如传入的参数 key 为 ‘id’, 传入的参数 value 为 ‘name’: {1: 'ye'} => [{id: 1, name: 'ye'}] 或 [{id: 1, name: 'ye'}] => {1: 'ye'}
 * 参数 toType: 数据将要转换的类型
 *    'object' ---- 对象数组改为对象：[{id: 1, name: 'ye'}] => {1: 'ye'}
 *    'array' ---- 对象改为对象数组：{1: 'ye'} => [{id: 1, name: 'ye'}]
 */
export default {
  listFormat: function(list, key, value, toType) {
    let lists
    switch (toType) {
      case 'object':
        lists = {}
        list.forEach((item) => {
          lists[item[key]] = item[value]
        })
        break
      case 'array':
        lists = []
        list.forEach((item, index) => {
          // lists[item[key]] = item[value]
          const data = {}
          data[key] = index
          data[value] = item
          list.push(data)
        })
        break
    }
    return lists
  }
}
