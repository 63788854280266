import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from './en'
import zh from './zh'
Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: localStorage.lang || 'zh-cn',
  messages: {
    en: {
      ...en
    },
    'zh-cn': {
      ...zh
    }
  }
})
export default i18n
