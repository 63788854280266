import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from '@/utils/request'
import store from './store'
import '@/style/index.scss'
import '@/style/interval.scss'
import '@/router/permission' // permission control

import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import moment from 'moment'
import { message } from 'ant-design-vue'
import dataProcessing from '@/utils/dataProcessing'
import lang from '@/i18n'
import VueClipboard from 'vue-clipboard2'

Vue.prototype.$lang = lang
Vue.prototype.$antMessage = message
message.config({
  top: `100px`
})
Vue.prototype.$axios = axios
Vue.prototype.dataProcessing = dataProcessing
//  全局过滤器 时间戳
Vue.filter('dateformat', function (dataStr, pattern = 'YYYY-MM-DD') {
  if (dataStr) {
    return moment(dataStr).format(pattern)
  } else {
    return dataStr
  }
})
Vue.use(Antd)
Vue.use(VueClipboard)

Vue.config.productionTip = false
document.title = '数字化管理平台'

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
