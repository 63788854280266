import router from '@/router'
import store from '@/store'
import { Modal } from 'ant-design-vue'
import Vue from 'vue'
Vue.use(Modal)

const whiteList = ['/login']

router.beforeEach((to, form, next) => {
  if (store.state.user.token) {
    Modal.destroyAll()
    next()
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next()
    } else {
      next(`/login?redirect=${to.path}`)
    }
  }
})
