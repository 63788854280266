import { setStorage, getStorage, removeStorage, removeAllStorage,  } from '@/utils/storage'
import * as mutationTypes from '@/store/mutation-types'

const token = mutationTypes.ADMIN_TOKEN;
const userId = mutationTypes.ADMIN_USER_ID;
const nickName = mutationTypes.ADMIN_NICK_NAME;
const avatar = mutationTypes.ADMIN_AVATAR;
const systemName = mutationTypes.ADMIN_SYSTEM_NAME;
const systemLogo = mutationTypes.ADMIN_SYSTEM_LOGO;

// token
export function setToken(val) {
  return setStorage(token, val)
}
export function getToken() {
  return getStorage(token)
}
export function removeToken() {
  return removeStorage(token)
}

// userId
export function setUserId(val) {
    return setStorage(userId, val)
}
export function getUserId() {
    return getStorage(userId)
}
export function removeUserId() {
    return removeStorage(userId)
}

// nickName
export function setNickName(val) {
    return setStorage(nickName, val)
}
export function getNickName() {
    return getStorage(nickName)
}
export function removeNickName() {
    return removeStorage(nickName)
}

// avatar
export function setAvatar(val) {
    return setStorage(avatar, val)
}
export function getAvatar() {
    return getStorage(avatar)
}
export function removeAvatar() {
    return removeStorage(avatar)
}

// systemName
export function setSystemName(val) {
  return setStorage(systemName, val)
}
export function getSystemName() {
  return getStorage(systemName)
}
export function removeSystemName() {
  return removeStorage(systemName)
}

// systemLogo
export function setSystemLogo(val) {
  return setStorage(systemLogo, val)
}
export function getSystemLogo() {
  return getStorage(systemLogo)
}
export function removeSystemLogo() {
  return removeStorage(systemLogo)
}

// reset all
export function removeAll() {
    return removeAllStorage([token, userId, avatar, nickName, systemName,systemLogo])
}
