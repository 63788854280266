const getters = {
  token: state => state.user.token,
  userId: state => state.user.userId,
  nickName: state => state.user.nickName,
  avatar: state => state.user.avatar,
  defaultAvatar: state => state.user.defaultAvatar,
  systemName: state => state.user.systemName,
  systemLogo: state => state.user.systemLogo,
}

export default getters
