module.exports = {
  dashboard: {
    name: 'Home', // 首页
    personal: 'personal', // 个人
    create: 'create', // 创建
    search: 'search', // 搜索
    all: 'all', // 全部
    application: 'application', // 应用
    portal: 'portal', // 门户
    application_package: 'Application package', // 应用包
    my_create: 'I created', // 我创建的
    management: 'management', // 管理
    address_book: 'address book', // 通讯录
    letter: 'Stand inside letter', // 站内信
    personal_center: 'Personal center', // 个人中心
    level_up: 'upgrade', // 升级
    store_center: 'Application center', // 应用中心
    logout: 'Log out', // 退出登录
    shop: 'mall', // 商城
    my_workspace: 'My Workspace', // 我的工作区
    join_workspace: 'I joined the workspace', // 我加入的工作区
    reading: 'read', // 已读
    bing_read: 'Mark all as read', // 全部标记为已读
    admin_user: 'administrator', // 管理员
    modify: 'editor', // 编辑
    change: 'change', // 更换
    is_default: 'As commonly used', // 设为常用
    basic_information: 'The basic information', // 基本信息
    copy: 'Copy the application', // 复制应用
    del: 'Delete the application', // 删除应用
    more: 'More and more', // 更多
    vacancy: 'Temporarily no data' // 暂无数据
  },
  personal: {
    center: 'Personal center', // 个人中心
    account: 'Account information', // 账号信息
    notify: 'Remind set', // 提醒设置
    basic_information: 'The basic information', // 基本信息
    name: 'name', // 姓名
    userName: 'userName', // 名称
    avatar: 'Head portrait', // 头像
    mobile: 'Mobile phone', // 手机
    safety_information: 'Safety information', // 安全信息
    modify: 'Modify', // 修改
    change: 'Change', // 更换
    save: 'save', // 保存
    cancel: 'cancel', // 取消
    password: 'password', // 密码
    pass_up: 'passing up', // 正在上传中
    modify_password: 'Change the password', // 修改密码
    old_password: 'old password', // 旧密码
    new_password: 'new password', // 新密码
    sure_modify: 'Sure to modify', // 确定修改
    desktop_notification: 'Desktop notification', // 桌面通知
    lang: 'language', // 语言
    desktop_msg: 'A popup notifies you of a new message (only for Chrome, Firefox and Safari)' // 桌面通知信息
  },
  management: {
    name: 'Management background', // 管理后台
    workspace_back: 'Workspace background', // 工作区后台
    admin_setting: 'Administrator Settings', // 管理员设置
    system_bill_detail: 'System billing details', // 系统账单明细
    outside_language: 'Outside the chain of language', // 外链语言
    outside_detail: 'Default language for linked content seen by people outside the workspace, such as form/report word links, email notifications, and so on', // 外链语言信息
    workspace_belong: 'Workspace attribution', // 工作区归属
    workspace_desc: 'Workspace attribution', // 工作区归属描述
    system_admin: 'System administrator', // 系统管理员
    system_admin_desc: 'Has all privileges except for setting up the system administrator', // 系统管理员描述
    add_system_admin: 'Add administrator', // 添加管理员
    user: 'member', // 成员
    search_user: 'Members of the search', // 搜索成员
    permission_illustrate: 'View permission description', // 查看权限说明
    permission_title: 'System administrator permissions', // 系统管理员权限说明
    enterprise_name: 'Enterprise name', // 企业名
    enterprise_logo: 'enterprise logo', // 企业 logo
    introduction: 'Introduction to the', // 简介
    lang: 'Select the language', // 选择语言
    language: 'Outside the chain of language', // 外链语言
    language_desc: 'default language for linked content seen by people outside the workspace, such as form/report sharing links, email notifications, and so on', // 外链语言描述
    introduction_desc: 'Click Add Workspace Profile', // 简介描述
    invite_member: 'Invite New Members', // 邀请新成员
    bill_detail: 'Billing details', // 账单明细
    relevant: 'invoice relevant', // 发票相关
    order_no: 'order no', // 订单编号
    sale_type: 'Transaction type', // 交易类型
    money: 'amount', // 金额
    status: 'state', // 状态
    play_status: 'payment', // 支付方式
    time: 'time', // 时间
    chinese: 'chinese' // 简体中文
  }
}
