
// 国际化
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN'
import en_US from 'ant-design-vue/lib/locale-provider/en_US'

const state = {
  local: zh_CN,
}

const mutations = {
  SET_LOCALE_TYPE(state, val) {
    state.local = val
  },
}

const actions = {
  SET_LOCALE({ commit }, val) {
    return new Promise((resolve) => {
      if (val === 'en') {
        localStorage.lang = 'en'
        commit('SET_LOCALE_TYPE', en_US)
        resolve()
      } else {
        localStorage.lang = 'zh-cn'
        commit('SET_LOCALE_TYPE', zh_CN)
        resolve()
      }
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
