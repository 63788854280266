module.exports = {
  dashboard: {
    name: '首页',
    personal: '个人',
    create: '创建',
    search: '搜索',
    all: '全部',
    application: '应用',
    portal: '门户',
    application_package: '应用包',
    my_create: '我创建的',
    management: '管理',
    address_book: '通讯录',
    letter: '站内信',
    personal_center: '个人中心',
    level_up: '升级',
    store_center: '应用中心',
    logout: '退出登录',
    shop: '商城',
    my_workspace: '我的工作区',
    join_workspace: '我加入的工作区',
    reading: '已读',
    bing_read: '全部标记为已读',
    admin_user: '管理员',
    modify: '编辑',
    change: '更换',
    is_default: '设为常用',
    basic_information: '基本信息',
    copy: '复制应用',
    del: '删除应用',
    more: '更多',
    vacancy: '暂无数据'
  },
  personal: {
    center: '个人中心',
    account: '账号信息',
    notify: '提醒设置',
    basic_information: '基本信息',
    name: '姓名',
    userName: '名称',
    mobile: '手机',
    avatar: '头像',
    safety_information: '安全信息',
    modify: '修改',
    change: '更换',
    save: '保存',
    cancel: '取消',
    password: '密码',
    pass_up: '正在上传中',
    modify_password: '修改密码',
    old_password: '旧密码',
    new_password: '新密码',
    sure_modify: '确定修改',
    desktop_notification: '桌面通知',
    lang: '语言',
    desktop_msg: '系统会弹出气泡通知你有新消息（仅对 Chrome 、Firefox 和 Safari 浏览器有效）'
  },
  management: {
    name: '管理后台',
    workspace_back: '工作区后台',
    admin_setting: '管理员设置',
    system_bill_detail: '系统账单明细',
    outside_language: '外链语言',
    outside_detail: '工作区外部人员看到的链接内容的默认语言,如表单/报表分字链接、邮件通知等',
    workspace_belong: '工作区归属',
    workspace_desc: '工作区归属',
    system_admin: '系统管理员',
    system_admin_desc: '具有除设置系统管理员外的所有权限',
    add_system_admin: '添加管理员',
    user: '成员',
    search_user: '搜索成员',
    permission_illustrate: '查看权限说明',
    permission_title: '系统管理员权限说明',
    enterprise_name: '企业名',
    enterprise_logo: '企业 logo',
    syetem_logo: '系统 logo',
    introduction: '简介',
    introduction_desc: '点击添加工作区简介',
    lang: '选择语言',
    invite_member: '邀请新成员',
    bill_detail: '账单明细',
    relevant: '发票相关',
    order_no: '订单编号',
    sale_type: '交易类型',
    money: '金额',
    status: '状态',
    play_status: '支付方式',
    time: '时间',
    chinese: '简体中文'
  }
}
