import Vue from 'vue'
import VueRouter from 'vue-router'
// 公告


Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    redirect: '/home',
    hidden: true,
    meta:{
      title: '首页',
    },
  }, {
    path: '/login',
    hidden: true,
    name: 'Login',
    meta:{
      title: '登录',
    },
    component: () => import('@/views/login')
  },{
    path: '/home',
    name: 'Home',
    meta:{
      title: '首页',
    },
    icon: 'home',
    component: ()=>(import('@/views/home/index'))
  },{
    path: '/personal',
    name: 'Personal',
    redirect: '/personal/center',
    hidden: true,
    meta:{
      title: '个人中心',
    },
    component: ()=>(import('@/views/personal/index')),
    children: [
      {
        path: 'center',
        name: 'Center',
        hidden: true,
        meta:{
          title: '个人中心',
        },
        component: ()=>(import('@/views/personal/center')),
      }
    ]
  },
  // {
  //   path: '/notice',
  //   name: 'Notice',
  //   meta:{
  //     title: '通知公告',
  //   },
  //   icon: 'notification',
  //   component: ()=>(import('@/views/notice/index')),
  //   children: [
  //     {
  //       path: 'announcementList',
  //       name: 'AnnouncementList',
  //       meta:{
  //         title: '公告管理',
  //       },
  //       icon: 'sound',
  //       component: ()=>(import('@/views/notice/announcementList/index'))
  //     },{
  //       path: 'noticeList',
  //       name: 'NoticeList',
  //       meta:{
  //         title: '通知管理',
  //       },
  //       icon: 'sound',
  //       component: ()=>(import('@/views/notice/noticeList/index'))
  //     }
  //   ]
  // },
  {
    path: '/enterprise',
    name: 'Enterprise',
    meta:{
      title: '企业',
    },
    icon: 'appstore',
    component: ()=>(import('@/views/enterprise/index')),
    children: [
      {
        path: 'structures',
        name: 'Structures',
        meta:{
          title: '企业列表',
        },
        redirect: 'structures/enterpriseList',
        icon: 'unordered-list',
        component: ()=>(import('@/views/enterprise/structures/index')),
        children: [
          {
            path: 'enterpriseList',
            name: 'EnterpriseList',
            meta:{
              title: '列表',
            },
            icon: 'account-book',
            component: ()=>(import('@/views/enterprise/structures/enterpriseList'))
          },{
            path: 'structureDetail/id=:id&name=:name',
            name: 'StructureDetail',
            meta:{
              title: '组织架构',
            },
            icon: 'account-book',
            component: ()=>(import('@/views/enterprise/structures/structureDetail'))
          },{
            path: 'adhibitionList/id=:id&name=:name',
            name: 'AdhibitionList',
            meta:{
              title: '应用',
            },
            icon: 'account-book',
            component: ()=>(import('@/views/enterprise/structures/adhibitionList'))
          }
        ]
      }
      // ,{
      //   path: 'billingDetails',
      //   name: 'BillingDetails',
      //   meta:{
      //     title: '账单明细',
      //   },
      //   icon: 'account-book',
      //   component: ()=>(import('@/views/enterprise/billingDetails/index'))
      // }
    ]
  }, {
    path: '/system',
    name: 'System',
    meta:{
      title: '系统管理',
    },
    icon: 'setting',
    redirect: '/system/dictionary',
    component: () => import('@/views/system/index'),
    // dictionaryList
    children: [
      {
        path: 'account',
        name: 'Account',
        meta:{
          title: '组织架构',
        },
        icon: 'apartment',
        redirect: 'account/structure',
        component: () => import('@/views/system/account/index'),
        children: [
          {
            path: 'structure',
            name: 'Structure',
            meta:{
              title: '组织架构',
            },
            icon: 'apartment',
            component: () => import('@/views/system/account/structure'),
          }
        ]
      },
      // {
      //   path: 'permission',
      //   name: 'Permission',
      //   meta:{
      //     title: '权限管理',
      //   },
      //   icon: 'safety-certificate',
      //   redirect: 'permission/permissionTree',
      //   component: () => import('@/views/system/permission/index'),
      //   children: [
      //     {
      //       path: 'permissionTree',
      //       name: 'PermissionTree',
      //       meta:{
      //         title: '权限树',
      //       },
      //       icon: 'safety-certificate',
      //       component: () => import('@/views/system/permission/PermissionTree'),
      //     }
      //   ]
      // },
      // {
      //   path: 'dictionaryDetail/:id',
      //   name: 'DictionaryDetail',
      //   meta:{
      //     title: '字典详情',
      //   },
      //   hidden: true,
      //   icon: 'profile',
      //   component: () => import('@/views/dictionary/dictionaryList/dictionaryDetail')
      // },
      {
        path: 'dictionary',
        name: 'Dictionary',
        meta:{
          title: '字典管理',
        },
        icon: 'read',
        redirect: 'dictionary/dictionaryItem',
        component: () => import('@/views/system/dictionaryList/index'),
        children: [
          {
            path: 'dictionaryItem',
            name: 'DictionaryItem',
            meta:{
              title: '列表',
            },
            icon: 'unordered-list',
            component: () => import('@/views/system/dictionaryList/dictionaryItem'),
          },
          {
            path: 'dictionaryDetail/:configId',
            name: 'DictionaryDetail',
            meta:{
              title: '详情',
            },
            hidden: true,
            icon: 'profile',
            component: () => import('@/views/system/dictionaryList/dictionaryDetail')
          }
        ]
      },
      {
        path: 'basicInfo',
        name: 'BasicInfo',
        meta:{
          title: '系统信息',
        },
        icon: 'profile',
        component: ()=>(import('@/views/system/basicInfo/index'))
      },
      {
        path: 'adhibition',
        name: 'Adhibition',
        meta:{
          title: '应用管理',
        },
        icon: 'read',
        redirect: 'adhibition/list',
        component: () => import('@/views/system/adhibition/index'),
        children: [
          {
            path: 'list',
            name: 'List',
            meta:{
              title: '应用列表',
            },
            icon: 'unordered-list',
            component: () => import('@/views/system/adhibition/list'),
          }
        ]
      },
    ]
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // redirect: '/',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
