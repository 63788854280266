<template>
  <div class="container">
    <div class="login-container">
      <div class="logo">
        <a-avatar :size="100" shape="square" :src="systemInfo.systemLogoFileUrl ? systemInfo.systemLogoFileUrl : require('@/assets/logo.png')" />
      </div>
      <h2 class="mb-20">{{ systemInfo.systemName }}</h2>
      <div class="main">
        <a-form
            ref="formLogin"
            class="user-layout-login"
            :form="form"
            @submit="handleSubmit"
        >

          <a-alert
              v-if="isLoginError"
              type="error"
              show-icon
              style="margin-bottom: 24px;"
              message="手机号或密码错误"
          />
          <a-form-item>
            <a-input
                v-decorator="[
                'tel',
                {rules: [{ required: true, message: '请输入手机号' }, { validator: handleTel }], validateTrigger: 'change'}
              ]"
                size="large"
                type="text"
                :placeholder="'手机号'"
            >
              <a-icon slot="prefix" type="user" :style="{ color: 'rgba(0,0,0,.25)' }" />
            </a-input>
          </a-form-item>

          <a-form-item>
            <a-input-password
                v-decorator="[
                'password',
                {rules: [{ required: true, message: '请输入密码！' }], validateTrigger: 'blur'}
              ]"
                size="large"
                :placeholder="'密码'"
            >
              <a-icon slot="prefix" type="lock" :style="{ color: 'rgba(0,0,0,.25)' }" />
            </a-input-password>
          </a-form-item>

          <a-form-item style="margin-top:24px">
            <a-button
                size="large"
                type="primary"
                html-type="submit"
                class="login-button"
                :loading="loginBtn"
                :disabled="loginBtn"
            >登录
            </a-button>
          </a-form-item>

        </a-form>

      </div>
    </div>
  </div>
</template>
<script>
import { login, loginSystemInfo } from '@/api/login'
export default {
  name: 'Login',

  data() {
    return {
      // loginType:1、扫码2、邮箱、3账号密码4、手机验证
      loginType: 3,
      // appType:1、总后台、2企业后台
      appType: 1,
      loginBtn: false,
      isLoginError: false,
      form: this.$form.createForm(this),
      redirect: undefined,
      systemInfo: {
        systemLogoFileUrl: '',
        systemName: ''
      }
    }
  },
  mounted() {
    if (this.$route.query.redirect) {
      this.redirect = decodeURIComponent(this.$route.query.redirect)
    }
    loginSystemInfo().then((res) => {
      if (res['code'] !== 0) {
        this.$antMessage.error(res['message'])
        return
      }
      this.systemInfo = res.data
      this.$store.dispatch('user/setSystemInfo', res.data)
    })
  },
  methods: {
    handleTel(rule, value, callback) {
      if (value) {
        if (/^(?:(?:\+|00)86)?1[3-9]\d{9}$/.test(value) === false) {
          return callback(new Error('请输入正确的手机号'))
        }
      }
      return callback()
    },
    handleSubmit(e) {
      e.preventDefault()
      const { form: { validateFields }} = this
      let validateFieldsKey = []
      validateFieldsKey = ['tel', 'password']
      validateFields(validateFieldsKey, { force: true }, (err, values) => {
        if (!err) {
          const loginParams = { ...values }
          Object.assign(loginParams, { loginType: this.loginType, appType: this.appType })
          login(loginParams).then(res => {
            if (res['code'] !== 0) {
              this.$antMessage.error(res['message'])
              return
            }
            this.$antMessage.success(res['message'])
            const path = this.redirect || '/'
            this.$store.dispatch('user/setLoginInfo', res.data).then(() => {
              this.$router.push({ path: path })
            })
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  height: 100vh;
  display: flex;
  align-items: center;

  .login-container {
    width: 328px;
    margin: 0 auto;
    height: 600px;

    .logo {
      width: 100px;
      margin: 0 auto;
    }

    .user-layout-login {
      label {
        font-size: 14px;
        float: left;
      }

      .getCaptcha {
        display: block;
        width: 100%;
        height: 40px;
      }

      .forge-password {
        font-size: 14px;
      }

      button.login-button {
        padding: 0 15px;
        font-size: 16px;
        height: 40px;
        width: 100%;
      }

      .user-login-other {
        text-align: left;
        margin-top: 24px;
        line-height: 22px;

        .item-icon {
          font-size: 24px;
          color: rgba(0, 0, 0, 0.2);
          margin-left: 16px;
          vertical-align: middle;
          cursor: pointer;
          transition: color 0.3s;

          &:hover {
            color: #1890ff;
          }
        }

        .register {
          float: right;
        }
      }
    }
  }
}
</style>
