import * as storage from '@/utils/auth'
import * as types from '../mutation-types'
import {logout} from '@/api/login'

const state = {
  token: storage.getToken(),
  userId: storage.getUserId(),
  nickName: storage.getNickName(),
  avatar: storage.getAvatar(),
  systemName: storage.getSystemName(),
  systemLogo: storage.getSystemLogo(),
  defaultAvatar: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
}

const mutations = {
  // reset all
  [types.ADMIN_RESET_ALL](state, data) {
    const remove = ['token', 'userId', 'nickName', 'avatar', 'systemName', 'systemLogo']
    for (const key in state) {
      if (Object.prototype.hasOwnProperty.call(state, key)) {
        if (remove.indexOf(key) > -1) {
          state[key] = data
        }
      }
    }
    storage.removeAll()
  },
  // token
  [types.ADMIN_TOKEN](state, data) {
    state.token = data
    data === '' ? storage.removeToken() : storage.setToken(data)
  },
  // userId
  [types.ADMIN_USER_ID](state, data) {
    state.userId = data
    data === '' ? storage.removeUserId() : storage.setUserId(data)
  },
  // nickname
  [types.ADMIN_NICK_NAME](state, data) {
    state.nickName = data
    data === '' ? storage.removeNickName() : storage.setNickName(data)
  },
  // avatar
  [types.ADMIN_AVATAR](state, data) {
    state.avatar = data
    data === '' ? storage.removeAvatar() : storage.setAvatar(data)
  },
  // systemName
  [types.ADMIN_SYSTEM_NAME](state, data) {
    state.systemName = data
    data === '' ? storage.removeSystemName() : storage.setSystemName(data)
  },
  // systemLogo
  [types.ADMIN_SYSTEM_LOGO](state, data) {
    state.systemLogo = data
    data === '' ? storage.removeSystemLogo() : storage.setSystemLogo(data)
  },
}

const actions = {
  // 登出
  logout({dispatch}) {
    return new Promise((resolve, reject) => {
      logout().then((res) => {
        if (res['code'] !== 0) {
          console.error(res['message'])
        }
        dispatch('resetAll')
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  // 清除所有信息
  resetAll({commit}) {
    commit(types.ADMIN_RESET_ALL, '')
  },
  // 设置登录信息（登录时调用）
  setLoginInfo({commit}, data) {
    commit(types.ADMIN_TOKEN, data.token)
    commit(types.ADMIN_USER_ID, data.userId)
    commit(types.ADMIN_AVATAR, data.portraitFileUrl)
    commit(types.ADMIN_NICK_NAME, data.nickName)
    commit(types.ADMIN_SYSTEM_NAME, data.systemName)
    commit(types.ADMIN_SYSTEM_LOGO, data.systemLogoFileUrl)
  },
  // 设置系统信息（修改系统信息时调用）
  setSystemInfo({commit}, data) {
    commit(types.ADMIN_SYSTEM_NAME, data.systemName)
    commit(types.ADMIN_SYSTEM_LOGO, data.systemLogoFileUrl)
  },
  // 设置用户信息（修改用户信息时调用）
  setUserInfo({commit}, data) {
    commit(types.ADMIN_NICK_NAME, data.nickName)
    commit(types.ADMIN_AVATAR, data.portraitFileUrl)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
