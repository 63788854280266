<template>
  <a-config-provider :locale="zh_CN">
    <div id="app">
      <layout></layout>
    </div>
  </a-config-provider>
</template>
<script>
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
import layout from '@/components/layout'

moment.locale('zh-cn')
export default {
  name: 'App',
  components: {
    layout
  },
  computed: {
    // lang() {
    //   if (true) {
    //     moment.locale('zh-cn')
    //   } else {
    //     moment.locale('en')
    //   }
    //   return this.$store.state.app.local
    // }
  },
  data() {
    return {
      zh_CN
    }
  }
}
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
