<template>
  <div>
    <Login v-if="$route.path === '/login'"></Login>
    <a-layout v-else id="components-layout-demo-custom-trigger">
      <!-- 侧边导航栏 -->
      <a-layout-sider v-model="collapsed" breakpoint="lg" :collapsedWidth="50" collapsible @collapse="onCollapse">
        <span slot="trigger" class="trigger">
          <a-icon class="trigger-icon" :type="collapsed ? 'menu-unfold' : 'menu-fold'"/>
        </span>
        <div class="logo p-10">
          <a-avatar :size="25" shape="square" :src="systemLogo? systemLogo : require('@/assets/logo.png')" />
          <span v-if="!collapsed" class="title ml-10">{{ systemName }}</span>
        </div>
        <a-menu
          mode="inline"
          theme="dark"
          :inlineIndent="20"
          :selected-keys="selectedKeys"
          :openKeys="openKeys"
          @openChange="onOpenChange"
        >
          <template v-for="item in $router.options.routes">
            <a-sub-menu v-if="!item.hidden && item.children && item.children.length > 0 " :key="item.name">
              <span slot="title">
                <a-icon :type="item.icon"/>
                <span>{{ item.meta.title }}</span>
              </span>
              <template v-for="i in item.children">
                <a-menu-item v-if="!i.hidden" :key="i.name" @click="toLink(item.path+'/', i)">
                  <a-icon :type="i.icon"/>
                  <span>{{ i.meta.title }}</span>
                </a-menu-item>
              </template>
            </a-sub-menu>
            <template v-else>
              <a-menu-item v-if="!item.hidden" :key="item.name" @click="toLink('', item)">
                <a-icon :type="item.icon"/>
                <span>{{ item.meta.title }}</span>
              </a-menu-item>
            </template>
          </template>
        </a-menu>
      </a-layout-sider>

      <!--右侧内容-->
      <a-layout>
        <!--头部-->
        <a-layout-header style="background: #fff; padding: 0">
          <div class="breadcrumb p-20">
            <a-breadcrumb>
              <span slot="separator" class="separator">/</span>
              <a-breadcrumb-item v-for="(item,index) of $route.matched" :key="index" style="padding:5px">
                <!--              <router-link :to="item.path" style="font-size:18px">{{ item.meta.title }}</router-link>-->
                <span style="font-size:18px">{{ item.meta.title }}</span>
              </a-breadcrumb-item>
            </a-breadcrumb>
            <div class="header-right">
              <a-dropdown :trigger="['click']" placement="topRight">
                <div class="user" style="cursor: pointer">
                  <a-avatar
                    shape="circle"
                    icon="user"
                    :src="avatar ? avatar : undefined"
                    class="mr-10"
                  ></a-avatar>
                  <span >{{nickName}}</span>
                </div>
                <a-menu slot="overlay" @click="handleChangeMenu" class="dropdown-menu">
                  <a-menu-item key="center">
                    <div class="menu-item">
                      <a-icon type="user" class="mr-10"/>
                      <span>个人中心</span>
                    </div>
                  </a-menu-item>
                  <a-menu-item key="logout">
                    <div class="menu-item">
                      <a-icon type="logout" class="mr-10"/>
                      <span>退出登录</span>
                    </div>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>

            </div>
          </div>
        </a-layout-header>
        <!--内容-->
        <a-layout-content class="layout-content" :class="this.$route.name === 'Home' || this.$route.name === 'Admin'? 'active': ''" >
          <router-view></router-view>
        </a-layout-content>
      </a-layout>
    </a-layout>
  </div>

</template>

<script>
import i18n from '@/i18n'
import store from '@/store'
import Login from '@/views/login/index'
// import { logout } from '@/main'
import router from "@/router";

export default {
  name: 'Sidebar',
  components: {
    Login
  },
  data() {
    // const { lang } = this.$route.params
    return {
      i18n: i18n,
      collapsed: false,
      selectedKeys: ['Home'],
      openKeys: [],
      backupsOpenKeys: [],
      defaultFileUrl: require('@/assets/logo.png'),
    }
  },
  computed: {
    nickName() {
      return this.$store.getters.nickName
    },
    avatar() {
      return this.$store.getters.avatar
    },
    systemName() {
      return this.$store.getters.systemName
    },
    systemLogo() {
      return this.$store.getters.systemLogo
    },
  },
  watch: {
    $route(to) {
      let matchedList = this.$router.history.current.matched
      if (matchedList.length > 1) {
        this.selectedKeys = [matchedList[1].name]
      } else if (matchedList.length === 0){
        this.selectedKeys = []
      }else {
        this.selectedKeys = [matchedList[0].name]
      }
      if (this.openKeys.length === 0) {
        this.openKeys = [to.matched[0].name]
      }
    }
  },
  created() {
    // 默认展开菜单
    this.openKeys = (this.$router.options.routes || [])
      .filter(item => !item.hidden && item.name && item.children && item.children.length)
      .map(item => item.name)
  },
  methods:{
    // 跳转
    toLink(path, item) {
      // this.selectMenuName = item.name
      this.$router.push({
        path: path + item.path
      })
    },
    // 一级菜单展开/收起
    onOpenChange(openKeys) {
      this.openKeys = openKeys
    },
    onCollapse(collapsed) {
      if (collapsed) {
        this.backupsOpenKeys = [...this.openKeys]
        this.openKeys = []
      } else {
        this.openKeys = [...this.backupsOpenKeys]
        this.backupsOpenKeys = []
      }
    },
    handleChangeMenu({key}) {
      switch (key) {
        case 'center':
          this.$router.push({
            path: '/personal'
          })
          break
        case 'logout':
          this.logout()
          break
        default:
      }
    },
    logout() {
      store.dispatch('user/logout').then(() => {
        router.push({
          path: `/login?redirect=${encodeURIComponent(this.$route.fullPath)}`
        }).then(() => {})
      })
    }
  }
}
</script>

<style lang="scss" scoped>
#components-layout-demo-custom-trigger {
  height: 100vh;
  position: relative;

  .logo {
    color: #fff;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .ant-menu-item, .ant-menu-submenu {
    text-align: left;
  }


  /deep/ .ant-layout-sider-children {
    .ant-menu-inline-collapsed {
      width: 50px;

      & > .ant-menu-item, & > .ant-menu-submenu {
        padding: 0 16px !important;
        font-size: 18px;
      }

      & > .ant-menu-submenu {
        padding: 0px !important;

        &.ant-menu-submenu-selected {
          background-color: #1890ff;
        }

        & > .ant-menu-submenu-title {
          padding: 0 16px !important;
          font-size: 18px;
        }
      }
    }

  }

  /deep/ .ant-layout-sider-trigger {
    height: 40px;
  }

  .trigger {
    .trigger-icon {
      position: absolute;
      bottom: 0;
      right: 0;
      color: #fff;
      font-size: 20px;
      padding: 10px 15px;
    }
  }

  .breadcrumb {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 64px;

    /deep/ .ant-breadcrumb-separator {
      margin-right: 0;
    }

    .separator {
      font-size: 22px;
    }
    .header-right{
      display: flex;
      align-items: center;
    }
  }

  .layout-content {
    //margin: 24px 16px;
    margin: 20px;
    padding: 24px;
    background: #fff;
    minHeight: 280px;
    height: calc(100vh - 180px);
    overflow-y: auto;
    &.active{
      padding: 0;
      background-color: #f0f2f5;
    }
  }
}

.dropdown-menu {
  width: 110px;

  .ant-dropdown-menu-item {
    .menu-item {
      display: flex;
      align-items: center;
    }
  }
}
.a{
  display: flex;
  align-items: center;
}
</style>
